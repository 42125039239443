import React from "react";
import { CaretDown } from "../../data/Data";

const To8s = ({
  svg:Svg,
  placehoder,
  value,
  dropdowns,
  dropdownId,
  toggleDropdown, 
  options,
  handleSelect,
  name,
  id,
}) => {
  const selectedOption = options?.find((option) => option.value === value);

  return (
    <div className="mb-3">
      <div
        className={`wm-dropdown-btn  ${
          dropdowns[dropdownId] ? "wm-active" : "" 
        }`}
        onClick={() => toggleDropdown(dropdownId)}
      >
        <div className="flex justify-between btnbtn21 btc bg rounded-lg items-center py-2 px-4 ">
          <div className="capitalize font-normal btc flex items-center">
            {Svg && <span><Svg size={14} color='var(--themeColor)' /></span>}
            <span>{selectedOption ? selectedOption.name : placehoder}</span>
          </div>
          <CaretDown color="var(--footText)" />
        </div>
      </div> 
      <div
        className={`wm-dropdown-containers  ${
          dropdowns[dropdownId] ? "wm-show" : ""
        }`}
      >
        <div className="btc py-2 overflow-y-auto max-h-72">
          {options.map((option) => (
            <div
              key={option.value}
              className="flex mx-5"
              onClick={() => handleSelect(option.value, name)}
            >
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                className="wm-checkbox-circle"
                checked={value === option.value}
                onChange={() => handleSelect(option.value, name)}
              />
              <div
                onClick={() => handleSelect(option.value, name)}
                className="cursor-pointer"
              >
                {option.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default To8s;
