import React from "react";

const To8a = ({ placehoder, name, type, onChange, value }) => {
  return (
    <>
      <textarea
        type={type ? type : "text"}
        placeholder={placehoder}
        className="textarea-btn bg h-20 text-xs  btnbtn21 rounded-md"
        onChange={onChange}
        value={value}
        name={name}
      />
    </>
  );
};

export default To8a;
