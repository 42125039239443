import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
const DataContext = createContext();
export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const { currentUser, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastSeen, setLastSeen] = useState("");
  const [userNickname, setUserNicknames] = useState("");
  const [userId, setUserId] = useState("");
  const [aboutMe, setaboutMe] = useState("");
  const [status, setstatus] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [language, setlanguage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userName, setUserName] = useState("");
  const [joinDate, setJoinDate] = useState("");

  const [isAdmin, setIsAdmin] = useState("");

  const [ipAddress, setIpAddress] = useState(null);

  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const lastSeenTime = currentUser.metadata.lastLoginAt;
        setLastSeen(lastSeenTime);

        if (userData) {
          setUserNicknames(userData.userName);
          setUserId(userData.userId);
          setIsAdmin(userData.isAdmin);
          setUserEmail(userData.userEmail);
          setUserCountry(userData.userCountry);
          setUserName(userData.userName);
          setJoinDate(userData.joinDate);
          setaboutMe(userData.aboutMe);
          setstatus(userData.status);
          setdateOfBirth(userData.dateOfBirth);
          setlanguage(userData.language);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();

    return () => {
      // Clean-up function if necessary
    };
  }, [userData, currentUser]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const value = {
    userData,
    lastSeen,
    loading,
    userNickname,
    userName,
    currentUser,
    userEmail,
    aboutMe,
    dateOfBirth,
    language,
    status,
    userId,
    userCountry,
    joinDate,
    isAdmin,
    ipAddress,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
