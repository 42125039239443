import React from "react";

const ButtonCard = ({
  className,
  type,
  text,
  uppercase,
  capitalize,
  theme,
  width,
  loading,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      isLoading={loading}
      style={{
        cursor: loading ? "not-allowed" : "pointer",
      }}
      className={`${className} w-full hover text-center rounded-md my-5 py-2 text-10 font-bold cursor-pointer  ${
        theme ? "btnbtn theme" : ""
      } ${width}    ${uppercase ? "uppercase" : ""} ${
        capitalize ? "capitalize" : ""
      }`}
    >
      {text}
    </button>
  );
};

export default ButtonCard;
