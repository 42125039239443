import React from "react";
import { Link } from "react-router-dom";

const SideLink = ({ text, url, onClick, theme, svg: Svg }) => {
  return (
    <Link to={url}>
      <div
        onClick={onClick}
        className={`text-sm flex items-center font-normal bord-yul py-3 px-4 hover overflow-hidden ${
          theme ? "theme rounded-b-lg" : ""
        }`}
      >
        <div> {text} </div>
        {Svg && (
          <div className="inline-block">
            <Svg size={12} color={"var(--themeColor)"} />
          </div>
        )}
      </div>
    </Link>
  );
};

export default SideLink;
