import React from "react";
import CheckText from "./CheckText";
import T11 from "./T11";
import To21 from "./To21";
import CheckToggle from "./CheckToggle";
import ButtonCard from "./ButtonCard";
import { Link } from "react-router-dom";
import To12 from "./To12";
import { useAuth } from "../../contexts/AuthContext";

const To22 = ({
  s1,
  sets1,
  s2,
  sets2,
  s3,
  sets3,
  terms,
  setTerms,
  ager,
  setAger,
  loading,
  handleRegister,
}) => {

  const { SignWithGoogle, } = useAuth();
  return (
    <div>
      {" "}
      <div className="theme-lighter my-3 rounded-md p-2 grid btc grid-cols-2">
        <T11 text={"Mínimo 8 caracteres"} />
        <T11 text={"Pelo menos um número"} />
        <T11 text={"Pelo menos um caractere minúnculo"} />
        <T11 text={"Pelo menos um caractere maiúsculo"} />
      </div>
      <To21
        text={"Autenticação de dois fatores"}
        desc={
          "Adicione mais segurança para sua conta com a autenticação de dois fatores."
        }
      />
      <div> 
        <CheckToggle
          text={"E-mail"}
          desc={"Receba um código de verificação no seu e-mail a cada login."}
          value={s1}
          setValue={sets1}
        />
        <CheckToggle
          text={"SMS"}
          desc={"Receba um código de verificação por SMS a cada login."}
          value={s2}
          setValue={sets2}
        />
        <CheckToggle
          text={"Whatsapp"}
          desc={"Receba um código de verificação no seu whatsapp a cada login."}
          value={s3}
          setValue={sets3}
        />
      </div>
      <div className="my-8">
        <CheckText
          text={"Li e aceito os"}
          urlText={"Termos de uso"}
          url={"/termos"}
          value={terms}
          setValue={setTerms}
        />
        <CheckText
          text={"Declaro e confirmo que tenho +18 anos"}
          value={ager}
          setValue={setAger}
        />
      </div>
      <ButtonCard
        text={!loading ? "Cadastrar" : "loading..."}
        onClick={!loading ? handleRegister : undefined}
        className={"themecom tcw py-3"}
      />
      <div className="flex text-sm justify-center gap-8 items-center">
        <div>Já possui uma conta?</div>
        <Link to={"/login"}>
          <div className="theme">Faça LogIn</div>
        </Link>
      </div>
      <div className="my-8">
        <To12 text={"Inscreva-se com o Google"} onClick={SignWithGoogle}/> 
      </div>
    </div>
  );
};

export default To22;
