import React from 'react'

const Overlay = ({card:Card}) => {
  return (
    <div>
        <div className='hidden'>hello</div>
        <div className="cookie-overlay"></div>
        <div className="cookie-consent bg text-sm">
            <Card/>
        </div>
    </div>
  )
}

export default Overlay