import React from "react";

const To15 = ({ title, subtitle }) => {
  return (
    <div>
      <div className="font-semibold text-lg mt-2">{title}</div>
      <div className="font-normal text-sm mb-5">{subtitle}</div>
    </div>
  );
};

export default To15;
