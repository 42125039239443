import React, { useEffect, useState } from "react";
import To15 from "./To15";
import To8 from "./To8";
import To8a from "./To8a";
import To25 from "./To25";
import To13 from "./To13";
import To13l from "./To13l";
import To28 from "./To28";
import To31 from "./To31";


const To26 = ({ value, setValue }) => {

  const [file, setFile] = useState("");
  const [prevImgs, setprevImgs] = useState([]);
  const [prevVids, setprevVids] = useState([]);

  const [cats, setcats] = useState([]); 

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValue((prevData) => {
      const updatedFormData = {
        ...prevData,
        [name]: value,
      };

      return updatedFormData;
    });
  };

  useEffect(() => {
    if (file) {
      const e = {
        target: {
          name: "content",
          value: file,
        },
      };
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [file]);

  useEffect(() => {
    if (prevImgs) {
      const e = {
        target: {
          name: "prevImgs",
          value: prevImgs,
        },
      };
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [prevImgs]);

  useEffect(() => {
    if (prevVids) {
      const e = {
        target: {
          name: "prevVids",
          value: prevVids,
        },
      };
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [prevVids]);

  useEffect(() => {
    if (cats) {
      const e = {
        target: {
          name: "cats",
          value: cats,
        },
      };
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [cats]);

  return (
    <div className="border-top-brown">
      <div>
        <To15
          title={"Titulo"}
          subtitle={"Preencha os dados que serão exibidos no anúncio."}
        />
        <To8
          placehoder={"Título do conteúdo*"}
          value={value.title}
          onChange={(e) => handleChange(e)}
          type={"text"}
          name={"title"}
        />
      </div>
      <div>
        <To15
          title={"Descrição"}
          subtitle={"Preencha os dados que serão exibidos no anúncio."}
        />
        <To8a
          placehoder={"Descreva brevemente o seu conteúdo*"}
          value={value.desc}
          onChange={(e) => handleChange(e)}
          type={"text"}
          name={"desc"}
        />
      </div>
      <div>
        <To15
          title={"Imagens do anúncio"}
          subtitle={
            "Adicione as fotos e vídeos que deseja exibir como prévia no seu anúncio."
          }
        />
        <To25
          title={"Fotos"}
          type={"img"} 
          text={"Adicionar foto"}
          limit={"Limite de 5MB"}
          value={prevImgs}
          setValue={setprevImgs}
          id={"fotos"}
        />
        <To25
          title={"Vídeos"}
          type={"img"}
          text={"Adicionar vídeo"}
          limit={"Limite de 20MB"}
          value={prevVids}
          setValue={setprevVids}
          id={"videos"}
        />
      </div>
      <div className="my-5">
        <To15
          title={"Categoria"}
          subtitle={"Adicione as categorias que se aplicam ao seu conteúdo."}
        />
        <To31 value={cats} setValue={setcats} />
      </div>
      <div>
        <To15
          title={"Conteúdo"}
          subtitle={"Adicione o conteúdo que o comprador irá ter acesso."}
        />
        <To13
          t1={"Adicionar mídia"}
          t3={"Limite de 20MB"}
          type={"file"}
          id={"mídia"}
          theme={true}
          value={file}
          setValue={setFile}
        />
        <div className="my-2 text-center text-sm ">ou</div>
        <To13l
          text={"Redirecionar para URL externa"}
          value={value.url}
          onChange={(e) => handleChange(e)}
          type={"text"}
          name={"url"}
        />
      </div>

      <div>
        <To15
          title={"Valor"}
          subtitle={"Adicione o valor que você irá cobrar."}
        />
        <To28
          text={"$"}
          value={value.price}
          onChange={(e) => handleChange(e)}
          placeholder={"Valor*"}
          sm={false}
          name={"price"}
        />
      </div>
    </div>
  );
};

export default To26;
