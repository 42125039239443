import React from "react";
import To8 from "./To8";
import { Delete1Icon } from "../../data/Data";

const To32 = ({ value, setValue }) => {
  // Add a new option to the array, each with its own country and data fields
  const addOption = () => {
    setValue([...value, { data: "" }]);
  };
  // Remove option at a specific index
  const removeOption = (index) => {
    const newOption = [...value];
    newOption.splice(index, 1);
    setValue(newOption);
  };

  // Update the value for the specific input fields
  const handleInputChange = (index, field, val) => {
    const newOptions = [...value];
    newOptions[index][field] = val;
    setValue(newOptions);
  };
  return (
    <div>
      {value.map((v, index) => (
        <div className="flex items-center justify-between" key={index}>
          <div className="w-11/12">
              <To8
                placehoder={"Adicionar categoria"}
                value={v.data} // Assign value from `v.data`
                setValue={(val) => handleInputChange(index, "data", val)} // Handle data input change
                type={"text"}
              />
          </div>

          <div
            onClick={() => removeOption(index)}
            className="cursor-pointer flex justify-end items-center"
          >
            <Delete1Icon color="var(--themeColor)" size={20} />
          </div>
        </div>
      ))}

      <div
        onClick={addOption}
        className="btnbtn w-1/2 sm:w-1/3 py-2 rounded-md text-center hover theme text-10 font-medium"
      >
        Adicionar categoria
      </div>
    </div>
  );
};

export default To32;
