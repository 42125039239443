import React from "react";
import { PlusIcon } from "../../data/Data";

const To27 = ({ text, onClick }) => {
  return (
    <div onClick={onClick} className="flex items-center theme text-xs font-medium cursor-pointer">
      <PlusIcon color="var(--themeColor)" size={12} />
      <div>{text}</div>
    </div>
  );
};

export default To27;
