import React, { useState } from "react";
import { ExLinkIcon } from "../../data/Data";
import To8 from "./To8";

const To13l = ({ text, onChange, value, name }) => {
  const [show, setshow] = useState(false);
  return (
    <div className="btnbtn p-5 cursor-pointer rounded-xl flex justify-center items-center flex-col ">
      {!show && ( 
        <div
          onClick={() => setshow(true)}
          className=" flex justify-center items-center flex-col"
        >
          <ExLinkIcon />
          <div className="theme text-sm font-medium">{text}</div>
          {value && <div className="text-10 my-1">{value}</div>}
        </div>
      )}

      {show && (
        <p className="w-full">
          <To8
            placehoder={text}
            value={value}
            onChange={onChange}
            type={"text"}
            name={name}
          />
          <div
            onClick={() => setshow(false)}
            className="themecom hover w-14 rounded-lg py-2 text-center font-medium text-sm"
          >
            Add
          </div>
        </p>
      )}
    </div>
  );
};

export default To13l;
