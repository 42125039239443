import React from 'react'

const To9 = ({title, desc}) => {
    return (
      <div className='mb-2 text-center mt-3 px-8'>
          <div className='font-semibold text-3xl theme'>{title}</div>
          <div className='text-sm mt-1 btc'>{desc}</div>
      </div>
    )
  }

export default To9