import React from "react";
import { HintIcon, QmarkIcon } from "../../data/Data";

const To8 = ({
  placehoder,
  infoText,
  type,
  value,
  setValue,
  onChange,
  error,
  name,
  id,
}) => {
  const isonChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <div className="input-container">
        <input
          type={type ? type : "text"}
          name={name ? name : "text"}
          id={id ? id : "text"}
          value={value}
          placeholder={placehoder}
          onChange={onChange || isonChange}
          className="input-btn btnbtn21 bg rounded-md"
        />
        {/* <div className="placeholder bg"></div> */}
      </div>
      {error && (
        <div className="flex red -mt-2 items-center mb-4 text-xs ">
          <QmarkIcon color="var(--themeColor)" size={10} />
          <div>{error}</div>
        </div>
      )}
      {infoText && (
        <div className="flex -mt-2 items-center mb-4 btc text-xs ">
          <HintIcon />
          <div>{infoText}</div>
        </div>
      )}
    </>
  );
};

export default To8;
