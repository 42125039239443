import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Container from "../controller/Container";
import {
  AdminAuth,
  ProtectedRoutes,
  // AuthPass,
  // AdminAuth,
} from "../utils/ProtectedRoute";
import PageIsLoading from "./general/PageIsLoading";

const Home = React.lazy(() => import("../pages/Home"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const Login = React.lazy(() => import("../pages/Login"));
const Register = React.lazy(() => import("../pages/Cadastre-se"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));

const Acompanhantes = React.lazy(() => import("../pages/user/Acompanhantes"));
const AcompanEncontre = React.lazy(() =>
  import("../pages/user/AcompanEncontre")
);
const AcompanContent = React.lazy(() =>
  import("../pages/user/AcompanContent")
);
const Regioes = React.lazy(() => import("../pages/user/Regioes"));
const Planos = React.lazy(() => import("../pages/user/Planos"));
const Perfil = React.lazy(() => import("../pages/user/Perfil"));

const AdminPanel = React.lazy(() => import("../pages/admin/AdminPanel"));

const Blog = React.lazy(() => import("../pages/Blog"));
const BlogCreate = React.lazy(() => import("../pages/BlogCreate"));
const Post = React.lazy(() => import("../pages/Post"));

export default function AppRouter() { 
  return (
    <Router>
      <Suspense fallback={<PageIsLoading />}>
        <Routes>
          <Route
            path="/"
            element={<Container card={Home} path={"home"} title={"home"} />}
          />
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/login"
              element={<Container card={Login} path={"home"} title={"login"} />}
            />
            <Route
              path="/register"
              element={
                <Container card={Register} path={"home"} title={"register"} />
              }
            />
            <Route
              path="/forgot-password"
              element={
                <Container
                  card={ForgotPassword}
                  path={"home"}
                  title={"forgot password"}
                />
              }
            />

            <Route
              path="/*"
              element={
                <Container
                  card={NotFound}
                  path={"home"}
                  title={"Not Found Page"}
                  board={"Not Found Page"}
                />
              }
            />
          </Route>

          <Route
            path="/acompanhantes"
            element={
              <Container
                card={Acompanhantes}
                path={"home"}
                title={"acompanhantes"}
              />
            }
          />

          <Route
            path="/u/:username"
            element={<Container card={Perfil} path={"home"} title={"perfil"} />}
          />
          <Route
            path="/u/:username/posts"
            element={
              <Container
                card={AcompanEncontre}
                path={"home"}
                title={"encontre acompanhantes"} 
              />
            }
          />
          <Route
            path="/u/:username/content/:query"
            element={
              <Container
                card={AcompanContent}
                path={"home"}
                title={"Venda de conteúdo"}
              />
            }
          />
          <Route
            path="/planos"
            element={<Container card={Planos} path={"home"} title={"planos"} />}
          />

          <Route
            path="/blog"
            element={<Container card={Blog} path={"home"} title={"blog"} />}
          />
          <Route
            path="/create-blog-post"
            element={
              <Container
                card={BlogCreate}
                path={"home"}
                title={"create blog post"}
              />
            }
          />
          <Route
            path="/blog/post/:query"
            element={<Container card={Post} path={"home"} title={"post"} />}
          />
          <Route
            path="/contato"
            element={
              <Container
                card={Post}
                type={"page"}
                path={"home"}
                title={"contato"}
              />
            }
          />
          <Route
            path="/política"
            element={
              <Container
                card={Post}
                type={"page"}
                path={"home"}
                title={"política"}
              />
            }
          />
          <Route
            path="/termos"
            element={
              <Container
                card={Post}
                type={"page"}
                path={"home"}
                title={"termos"}
              />
            }
          />
          <Route
            path="/denunciar"
            element={
              <Container
                card={Post}
                type={"post"}
                path={"home"}
                title={"denunciar"}
              />
            }
          />

          <Route
            path="/regioes"
            element={
              <Container card={Regioes} path={"home"} title={"regioes"} />
            }
          />

          {/* <Route element={<AuthPass />}> */}
          <Route
            path="/admin-panel"
            element={
              <Container
                card={AdminPanel}
                path={"home"}
                title={"admin panel"}
              />
            }
          />

          <Route element={<AdminAuth />}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
}
