import React, { useEffect, useState } from "react";
import CheckText from "./CheckText";
import To32 from "./To32";

const To31 = ({setValue }) => {
  const [serv1, setserv1] = useState(false);
  const [serv2, setserv2] = useState(false);
  const [serv3, setserv3] = useState(false);
  const [serv4, setserv4] = useState(false);
  const [serv5, setserv5] = useState([]);

  useEffect(() => {
    const newValues = [];
    if (serv1) newValues.push("foto nua");
    if (serv2) newValues.push("vídeo nu");
    if (serv3) newValues.push("link nu");
    if (serv4) newValues.push("vídeo de masturbação");

    if (serv5 && serv5.length > 0) {
      serv5.forEach((item) => {
        if (item.data) {
          newValues.push(item.data);
        }
      });
    }

    setValue(newValues);
    /* eslint-disable-next-line */
  }, [serv1, serv2, serv3, serv4, serv5]);


  return (
    <div>
      <div className="md:grid md:grid-cols-2 -mt-3">
        <CheckText text={"foto nua"} value={serv1} setValue={setserv1} />
        <CheckText text={"vídeo nu"} value={serv2} setValue={setserv2} />
        <CheckText text={"link nu"} value={serv3} setValue={setserv3} />
        <CheckText
          text={"vídeo de masturbação"}
          value={serv4}
          setValue={setserv4}
        />
      </div>
      <To32 value={serv5} setValue={setserv5} />
    </div>
  );
};

export default To31;
