import React from "react";
import { CheckCircleIcon, InfoIcon } from "../../data/Data";

const T11 = ({ text, icon, lu, bold }) => {
  return (
    <div
      className={`flex items-center gap-1 mb-2 text-10 ${
        bold ? "font-semibold" : ""
      }`}
    >
      {icon === "info" ? <InfoIcon /> : <CheckCircleIcon />}
      <div className="w-4/5">
        {text} {lu && <span className="uppercase font-bold">{lu}.</span>}
      </div>
    </div>
  );
};

export default T11;
