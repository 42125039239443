import React from "react";
import { CheckBoxIcon } from "../../data/Data";

const To21 = ({ text, desc, value, setValue }) => {
  return (
    <div className="flex w-full justify-start items-start gap-2 my-5">
      <p className="-mt-4"><CheckBoxIcon /></p>
      <div className=" text-xs btc">
        <div className="font-medium theme">{text}</div>
        <div className="text-10">{desc}</div>
      </div>
    </div>
  );
};

export default To21;
