import React from "react";
import { CaretDown, LogOutSvg, PFPIcon } from "../../data/Data";
import SideLink from "./SideLink";

const To24 = ({ userData, logout }) => {
  return (
    <>
      <div className="flex justify-between items-center mb-2 pt-5 pb-2 px-4 bord-p-bot">
        <div className="flex justify-between items-center ">
          <PFPIcon size={35} />
          <div className="font-medium text-sm">{userData?.userFullname}</div>
        </div>
        <div className="flex  items-center">
          <div className="bg-green-500 w-2 h-2 rounded-full -mr-0.5"></div>
          <CaretDown color="#808080" />
        </div>
      </div>
      <SideLink text={"Meu perfil"} url={"/"} />
      <SideLink text={"Minhas compras"} url={"/"} />
      <SideLink text={"Meu histórico"} url={"/"} />
      <SideLink text={"Editar perfil"} url={"/"} />
      <SideLink text={"Configurações da conta"} url={"/"} />
      <SideLink text={"Suporte"} url={"/"} />
      <SideLink text={"Sair"} onClick={logout} theme={true} svg={LogOutSvg} />
    </>
  );
};

export default To24;
