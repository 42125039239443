import { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { MoonIcon, SunIcon } from "../../data/Data";

const ThemeToggle = () => {
  const { toggle, theme } = useContext(ThemeContext);

  return (
    <div className="themetoggle-container cursor-pointer" onClick={toggle}>
      <div>{theme === "dark" ? <MoonIcon /> : <SunIcon />}</div>
    </div>
  );
};

export default ThemeToggle;