import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../../data/Data";

const FootSocial = () => {
  const Foot = ({ card: Card }) => {
    return (
      <div className="foot-social cursor-pointer">
        <Card />
      </div>
    );
  };
  return (
    <div className="foot-social-con my-8">
      <Foot card={FacebookIcon} />
      <Foot card={InstagramIcon} />
      <Foot card={LinkedinIcon} />
      <Foot card={TwitterIcon} />
      <Foot card={YoutubeIcon} />
    </div>
  );
};

export default FootSocial;
