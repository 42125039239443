export const generateUid6 = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString = Math.random().toString(36).substr(2, 4); // Generating a random string of 4 characters
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomPart = "";
  for (let i = 0; i < 2; i++) {
    // Looping twice to get 2 more characters
    randomPart += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return `${timestamp}-${randomString}-${randomPart}`;
};

export const generateUid8 = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString1 = Math.random().toString(36).substr(2, 3); // Generating a random string of 3 characters
  const randomString2 = Math.random().toString(36).substr(2, 3); // Generating another random string of 3 characters
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomPart = "";
  for (let i = 0; i < 2; i++) {
    // Looping twice to get 2 more characters
    randomPart += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return `${timestamp}-${randomString1}-${randomString2}-${randomPart}`;
};

export const generateUid16 = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString1 = Math.random().toString(36).substr(2, 6); // Random string, first part
  const randomString2 = Math.random().toString(36).substr(2, 6); // Random string, second part
  const randomString3 = Math.random().toString(36).substr(2, 4); // Random string, third part
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomPart = "";
  for (let i = 0; i < 4; i++) {
    randomPart += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return `${timestamp}-${randomString1}-${randomString2}-${randomString3}-${randomPart}`;
};

export const getSex = (value) => {
  let mysex;
  if (value === "male") {
    mysex = "escortMens";
  } else if (value === "female") {
    mysex = "escortWomens";
  } else {
    mysex = "escortTrans";
  }
  return mysex
}

export const toSlug = (text) => {
  return text
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing spaces
    .replace(/[^\w\s-]/g, "") // Remove special characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
};

export const generateSlugId = (slug) => {
  return slug.length <= 38 ? slug : slug.slice(0, 38);
};



export const calculateAge = (date) => {
  // Parse the date from the string (format: "YYYY-MM-DD")
  const birthDate = new Date(date);
  const today = new Date();

  // Calculate the difference in years
  let age = today.getFullYear() - birthDate.getFullYear();

  // Adjust if the current date is before the birth date this year
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--; // Decrease age if birthday hasn't occurred yet this year
  }

  return age;
}

export const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
  ],
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
