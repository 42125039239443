import React from 'react'
import ToggleCard from './ToggleCard'

const CheckToggle = ({text, desc, value, setValue}) => {
  return (
    <div className='flex btc justify-between items-center mb-3'>
        <div className=' text-xs '>
            <div className='font-bold'>{text}</div>
            <div className='text-10'>{desc}</div>
        </div>
        <ToggleCard value={value} setValue={setValue}/>
    </div>
  )
}

export default CheckToggle