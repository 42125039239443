import React from "react";

const Step = ({ currentStep, tile3 }) => {
  return (
    <div className="my-5 btc">
      <div className="mx-5 flex items-center text-sm gap-0 m-0">
        <div
          className={`${
            currentStep > 0 ? "btnbtn theme" : "btnbtn21"
          } w-8 h-8 p-2 text-sm -mr-2  rounded-full text-center flex justify-center items-center`}
        >
          01
        </div>

        <div
          className={`${currentStep > 1 ? "btnbtn" : "bglb"} hash234  w-full `}
        ></div>

        <div
          className={`${
            currentStep > 1 ? "btnbtn theme" : "btnbtn21"
          } w-8 h-8 p-2 text-sm -m-2 rounded-full text-center flex justify-center items-center`}
        >
          02
        </div>

        <div
          className={`${currentStep > 2 ? "btnbtn" : "bglb"} hash234  w-full `}
        ></div>

        <div
          className={`${
            currentStep > 2 ? "btnbtn theme" : "btnbtn21"
          } w-8 h-8 p-2 text-sm -ml-2 rounded-full text-center flex justify-center items-center`}
        >
          03
        </div>
      </div>
      <div className="grid grid-cols-3 text-xs">
        <div className={`text-start pl-3 ${currentStep > 0 ? "theme" : ""}`}>
          Cadastro
        </div>
        <div className={`text-center ${currentStep > 1 ? "theme" : ""}`}>
          Validação
        </div>
        <div className={`text-end ${currentStep > 2 ? "theme" : ""}`}>
          {tile3}
        </div>
      </div>
    </div>
  );
};

export default Step;
