import React, { useState } from "react";
import To14 from "./To14";
import { CloudUploadIcon, Delete1Icon } from "../../data/Data";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../utils/init-firebase";
import { useAuth } from "../../contexts/AuthContext";

const To25 = ({ title, type, text, limit, value, setValue, id }) => {
  const { currentUser } = useAuth();
  const [pros, setPros] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setLoading(true);
    setPros((prevPros) => [...prevPros, ...files]);

    // Upload only new files
    const newUploads = files.map(async (file) => {
      const storageImgRef = ref(
        storage,
        `users/${currentUser?.uid}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageImgRef, file);

      // Get download URL after upload
      const downloadUrl = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
      return downloadUrl;
    });

    try {
      const uploadedUrls = await Promise.all(newUploads); // Wait for all uploads to complete

      // Update state with new URLs
      setValue((prevValue) => [...prevValue, ...uploadedUrls]); // Also update the `value` array
    } catch (error) {
      console.error("Upload failed:", error);
    }

    setLoading(false); // Stop loading
  };

  const removeImage = (index) => {
    const updatedImages = [...pros];
    updatedImages.splice(index, 1);
    setPros(updatedImages);
    setValue(updatedImages);
  };

  const isImageFile = (file) => {
    return file && file.type.startsWith("image/");
  };

  const isVideoFile = (file) => {
    return file && file.type.startsWith("video/");
  };

  const limita = 6;
  return (
    <div>
      <To14 text={title} size={"sm"} />
      <div className="my-2 grid grid-cols-2 gap-x-2 md:grid-cols-3">
        {pros.map((image, index) => (
          <div className="flex flex-col">
            <div key={index} className="btnbtn21 rounded-xl sm:w-32 h-32">
              {isImageFile(image) && (
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  className="w-full h-full object-cover rounded-xl"
                />
              )}
              {isVideoFile(image) && (
                <video
                  src={URL.createObjectURL(image)}
                  alt={value.name}
                  controls
                  className="h-full w-full object-cover rounded-xl"
                />
              )}
            </div>
            {loading && <div key={index}>Uploading...</div>}
            <div className="my-1 hover" onClick={() => removeImage(index)}>
              <Delete1Icon size={19} />
            </div>
          </div>
        ))}

        {pros.length < limita && (
          <label htmlFor={id} className="">
            <input
              id={id}
              type="file"
              name={id}
              accept="image/*,video/*"
              onChange={handleImageChange}
              className="hidden"
            />
            <div className="btnbtn sm:w-32 h-32 rounded-xl flex justify-center items-center flex-col font-sm">
              <CloudUploadIcon />
              <div className="theme font-semibold text-xs">{text}</div>
              <div className="text-10">{limit}</div>
            </div>
          </label>
        )}
      </div>
    </div>
  );
};

export default To25;
