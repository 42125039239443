import { Navigate, useLocation, Outlet } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import PageIsLoading from "../components/general/PageIsLoading";
import React from "react";
import NotMember from "../components/cards/NotMember";
import Container from "../controller/Container";

export function ProtectedRoutes() {
  const { currentUser, isloading } = useAuth();
  let targetRoute = "/acompanhantes";

  if (isloading) {
    return (
      <div className="theme1">
        <PageIsLoading />
      </div>
    );
  }

  if (currentUser) {
    return <Navigate to={targetRoute} />;
  }
  return <Outlet />;
}

export function AuthPass() {
  const { currentUser, isloading , userData } = useAuth();
  const location = useLocation();

  if (isloading) {
    return (
      <div className="theme1">
        <PageIsLoading />
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (currentUser && !userData?.isMember) {
    return <Container card={NotMember} path={"home"} title={"Incoming"} />;
  }
  if (
    currentUser &&
    userData?.isMember &&
    ["/login", "/register"].includes(location.pathname)
  ) {
    return <Navigate to={"/acompanhantes"} />;
  }

  return <Outlet />;
}


export function AdminAuth() {
  const location = useLocation();
  const { userData, isloading, currentUser } = useAuth();

  if (isloading) {
    return (
      <div className="theme1">
        <PageIsLoading />
      </div>
    );
  }

  if (currentUser && userData?.isAdmin) {
    return <Outlet />;
  }

  return <Navigate to="/acompanhantes" state={{ from: location }} />;
}
