import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Consent from "../components/general/Consent";
import { useGeneralBrain } from "./Brain";
import Navbar from "../components/Navbar";
import { projectName } from "../data/Info";
import ToDo from "./ToDo";
import { useAuth } from "../contexts/AuthContext";
import PageIsLoading from "../components/general/PageIsLoading";
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "../contexts/ThemeContext";
import { useLocation } from "react-router-dom";

const Container = ({ card: Card, title, path, type }) => {
  const { currentUser, setwho, userData } = useGeneralBrain();
  const { register, isloading } = useAuth();
  const [open, setOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.title = `| ${title} - ${projectName} |`;
    window.scrollTo(0, 0);
    setwho(path);
    setOpen(false);
  }, [title, path, setwho]);

  const [todo, settodo] = useState(false);

  useEffect(() => {
    if (currentUser && userData === undefined) {
      const whoa = localStorage.getItem("mywho") || "client";
      register({
        email: currentUser.email,
        fullname: currentUser.displayName,
        username: currentUser.email.replace("@gmail.com", ""),
        userId: currentUser.uid,
        who: whoa,
        create: false,
        emailVerified: currentUser.emailVerified,
      });

      localStorage.removeItem("mywho");
    }
    settodo(userData?.todo);
  }, [userData, currentUser, register]);

  useEffect(() => {
    if (!currentUser) {
      settodo(false);
      window.scrollTo(0, 0);
    }
  }, [currentUser]);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  if (isloading) {
    return <PageIsLoading />;
  }
  return (
    <p>
      <div className="container-app overflow-x-hidden">
        <div className="general-container overflow-x-hidden">
        <Navbar open={open} setOpen={setOpen} />
          {!todo && <Card type={type} title={title} />}
          {todo && <ToDo status={userData?.todo} />}
        </div>
        <ToastContainer
          position="bottom-right"
          theme={theme === "light" ? "light" : "dark"}
        />
        <Footer />
        <Consent />
      </div>
    </p>
  );
};

export default Container;
