import React from "react";
import { ToggleOffIcon, ToggleOnIcon } from "../../data/Data";

const ToggleCard = ({ value, setValue }) => {
  return (
    <div className="" onClick={() => setValue(value === true ? false : true)}>
      {value && <ToggleOnIcon />}
      {!value && <ToggleOffIcon />}
    </div>
  );
};

export default ToggleCard;
