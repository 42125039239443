import React from 'react'
import { GoogleIcon } from '../../data/Data'

const To12 = ({text, onClick}) => {
  return (
    <div onClick={onClick}  className="btnbtn2 flex justify-center hover mt-5 items-center py-2 rounded-md">
          <GoogleIcon />
          <div className="text-sm font-medium ">{text}</div>
        </div>
  )
}

export default To12