import React, { useEffect, useState } from "react";
import To9 from "../cards/To9";
import Step from "../cards/Step";
import { sexOptions } from "../../data/Input";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import { useGeneralBrain } from "../../controller/Brain";
import { toast } from "react-toastify";
import { generateUid16, getSex } from "../../controller/Functions";
import { doc, runTransaction, serverTimestamp } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { useNavigate } from "react-router-dom";

const Sellers = ({
  fullname,
  setFullname,
  efullname,
  username,
  setUsername,
  eusername,
  email,
  setRegisterEmail,
  eregisterEmail,
  password,
  setRegisterPassword,
  eregisterPassword,
  confirmPassword,
  setConfirmPassword,
  econfirmPassword,
  terms,
  setTerms,
  ager,
  setAger,
  s1,
  s2,
  s3,
  sets1,
  sets2,
  sets3,
  handleRegister,
  loading,
  who,
  step,
  setStep,
}) => {
  const { userData, currentUser } = useGeneralBrain();
  const [dropdowns, setDropdowns] = useState(Array(18).fill(false));

  let title;
  let desc;

  if (step === 1) {
    title = "Faça seu cadastro!";
    desc =
      "Complete seu cadastro preenchendo os dados abaixo e crie seu anúncio!";
  } else if (step === 2) {
    title = "Valide sua identidade";
    desc =
      "Complete seu cadastro preenchendo os dados abaixo e crie seu anúncio!";
  } else if (step === 3) {
    title =
      userData?.who === "escort" ? "Crie seu anúncio" : "Publique seu conteúdo";
    desc =
      "Complete seu cadastro preenchendo os dados abaixo e crie seu anúncio!";
  }

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

  const tile3 =
    userData?.who === "escort" ? "Criar anúncio" : "Publicar conteúdo";
  // //12 reviews
  // reviews: {
  //   img: "",
  //   name: "",
  //   time: "",
  //   rate: "",
  //   message: "",
  // },

  const [values, setvalues] = useState([{ time: "", price: "" }]);
  const [isloading, setisloading] = useState(false);

  const [contents, setcontents] = useState({
    price: "",
    title: "",
    desc: "",
    content: "",
    url: "",
    prevImgs: [],
    prevVids: [],
    cats: [],
  });

  const [escortData, setEscortData] = useState({
    imgCover: "",
    imgPfp: "",
    imgAds: "",
    imgs: [],
    imgsVid: [],

    awho: userData?.who,
    arate: userData?.myRate,
    ausername: userData?.userName,
    afullname: "",
    asex: "",
    adob: "",
    aservice: "",
    atelephone: "",
    awhatsapp: "",
    adesc: "",

    lcountry: "",
    lstate: "",
    lcity: "",

    slown: false,
    slhotel: false,
    slmotel: false,
    slevent: false,
    slhome: false,
    slother: false,

    myvalues: values,

    myservs1: false,
    myservs2: false,
    myservs3: false,
    myservs4: false,
    myservs5: false,
    myservs6: false,
    myservs7: false,
    myservs8: false,
    myservs9: false,
    myservs10: false,

    pmmoney: false,
    pmpix: false,
    pmcredit: false,
    pmdebit: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEscortData((prevData) => {
      const updatedFormData = {
        ...prevData,
        [name]: value,
      };

      return updatedFormData;
    });
  };

  useEffect(() => {
    const name = "myvalues";
    setEscortData((prevData) => {
      const updatedFormData = {
        ...prevData,
        [name]: values,
      };

      return updatedFormData;
    });
  }, [values]);

  const handleSelect = (value, name) => {
    const e = {
      target: {
        name,
        value,
      },
    };
    handleChange(e);
    setDropdowns(Array(18).fill(false));
  };

  const handleCheck = (value, name) => {
    const e = {
      target: {
        name,
        value: value === true ? false : true,
      },
    };
    handleChange(e);
    setDropdowns(Array(18).fill(false));
  };

  const navigate = useNavigate();

  const handleUpload = async (type) => {
    try {
      setisloading(true);
      const mysexdoc = getSex(escortData.asex);
      const uniqueDocId = generateUid16();
      await runTransaction(db, async (transaction) => {
        const escortContentsRef = doc(db, "escortContents", currentUser.uid);
        transaction.update(escortContentsRef, {
          [uniqueDocId]: contents,
        });

        const at1 = {
          todo: "",
          status: "active",

          imgCover: escortData.imgCover,
          imgPfp: escortData.imgPfp,
          imgAds: escortData.imgAds,
          imgs: escortData.imgs,
          imgsVid: escortData.imgsVid,

          userFullname: escortData.afullname,
          myGender: escortData.asex,

          adob: escortData.adob,
          aservice: escortData.aservice,
          atelephone: escortData.atelephone,
          awhatsapp: escortData.awhatsapp,
          adesc: escortData.adesc,

          lcountry: escortData.lcountry,
          lstate: escortData.lstate,
          lcity: escortData.lcity,

          slown: escortData.slown,
          slhotel: escortData.slhotel,
          slmotel: escortData.slmotel,
          slevent: escortData.slevent,
          slhome: escortData.slhome,
          slother: escortData.slother,

          myvalues: escortData.myvalues,

          myservs1: escortData.myservs1,
          myservs2: escortData.myservs2,
          myservs3: escortData.myservs3,
          myservs4: escortData.myservs4,
          myservs5: escortData.myservs5,
          myservs6: escortData.myservs6,
          myservs7: escortData.myservs7,
          myservs8: escortData.myservs8,
          myservs9: escortData.myservs9,
          myservs10: escortData.myservs10,

          pmmoney: escortData.pmmoney,
          pmpix: escortData.pmpix,
          pmcredit: escortData.pmcredit,
          pmdebit: escortData.pmdebit,
        };

        const iallow = "";

        const at2 = {
          d1: escortData.imgAds,
          d2: escortData.imgs,
          d3: escortData.imgsVid,
          d4: userData?.myPlan,
          d5: escortData.afullname,
          d6: escortData.myvalues,
          d7: userData?.myRate,
          d8: escortData.adob,
          d9: escortData.lcity,
          d10: escortData.slown,
          d11: escortData.asex,
          d12: userData?.userName,
          d13: userData?.myFollowers,
          d14: serverTimestamp(),
          d15: iallow,
        };

        const userRef = doc(db, "users", currentUser.uid);
        transaction.update(userRef, at1);
        if (type === "publish") {
          const liveRef = doc(db, "lives", mysexdoc);
          transaction.update(liveRef, {
            [currentUser.uid]: at2,
          });
        }
      }).then(navigate("/acompanhantes"));
    } catch (error) {
      console.error("Error here:", error);
      setisloading(true);
    }
  };

  const publishNow = async () => {
    try {
      await handleUpload("publish");
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const saveDraft = async () => {
    try {
      await handleUpload("draft");
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  const cancelNow = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const userRef = doc(db, "users", currentUser.uid);
        transaction.update(userRef, {
          todo: "",
        });
        toast("Done succesfully!");
      }).then(navigate("/acompanhantes"));
    } catch (error) {
      console.error("Error here:", error);
    }
  };

  return (
    <div className="sm:flex sm:justify-center sm:items-center">
      <div className="c-form-container width-lg transition-all ease-in-out">
        <To9 title={title} desc={desc} />

        <Step currentStep={step} tile3={tile3} />
        {step === 1 && (
          <Step1
            setStep={setStep}
            step={step}
            fullname={fullname}
            setFullname={setFullname}
            efullname={efullname}
            username={username}
            setUsername={setUsername}
            eusername={eusername}
            email={email}
            setRegisterEmail={setRegisterEmail}
            eregisterEmail={eregisterEmail}
            password={password}
            setRegisterPassword={setRegisterPassword}
            eregisterPassword={eregisterPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            econfirmPassword={econfirmPassword}
            terms={terms}
            setTerms={setTerms}
            ager={ager}
            setAger={setAger}
            s1={s1}
            s2={s2}
            s3={s3}
            sets1={sets1}
            sets2={sets2}
            sets3={sets3}
            loading={loading}
            handleRegister={handleRegister}
          />
        )}

        {step === 2 && <Step2 step={step} setStep={setStep} name={fullname} />}

        {step === 3 && (
          <>
            <Step3
              who={userData?.who}
              toggleDropdown={toggleDropdown}
              handleSelect={handleSelect}
              sexOptions={sexOptions}
              dropdowns={dropdowns}
              escortData={escortData}
              setEscortData={setEscortData}
              publishNow={publishNow}
              saveDraft={saveDraft}
              cancelNow={cancelNow}
              handleChange={handleChange}
              handleCheck={handleCheck}
              values={values}
              setvalues={setvalues}
              contents={contents}
              setcontents={setcontents}
              loading={isloading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Sellers;
