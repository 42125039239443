import React from "react";
import { Link } from "react-router-dom";

const CheckText = ({
  text,
  url,
  urlText,
  handleChange,
  value,
  setValue,
  onClick,
  name,
}) => {
  const handleClick = () => {
    if (setValue) {
      setValue(value === true ? false : true);
    }

    if (handleChange) {
      handleChange(value, name);
    }
  };
  return (
    <div
      onClick={handleClick}
      className="flex text-sm my-3 btc transition-all  ease-in-out"
    >
      <div
        className={`w-4 h-4 rounded-sm flex justify-center items-center ${
          value ? "btnbtn" : "btnbtn21"
        }`}
      >
        {value && <span className="w-2 h-2 rounded-sm themecom"></span>}
      </div>
      <div>
        {text}{" "}
        {url && (
          <Link to={url}>
            <span className="theme underline">{urlText}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CheckText;
