import React from "react";
import Sellers from "../components/Cadastre/Sellers";

const ToDo = ({ status }) => {
  return (
    <div>
      
      {status === "verify" && <Sellers step={2} />}
      {status === "criar" && <Sellers step={3} />}
      {status === "publicar" && <Sellers step={3} />}
    </div>
  );
};

export default ToDo;
