import React, { createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { formatDistanceToNow } from "date-fns";
import { useData } from "../contexts/DataContext";
import { useAuth } from "../contexts/AuthContext"; 

const GeneralBrainContext = createContext();

export const GeneralBrainProvider = ({ children }) => {
  const {
    lastSeen,
    loading,
    userNickname,
    userName,
    userEmail,
    aboutMe,
    dateOfBirth,
    language,
    status,
    userId,
    userCountry,
    joinDate,
    isAdmin,
    ipAddress,
  } = useData();
  const { currentUser, userData } = useAuth()

  const[who, setwho] = useState("")

  const TimeAgo = (time) => {
    const date = time.toDate();
    const timeAgo = formatDistanceToNow(date, { addSuffix: true });
    const formattedTime = timeAgo.replace("about", "");
    return formattedTime;
  };

 
  const generateUid6 = () => {
    const timestamp = new Date().getTime().toString(36);
    const randomString = Math.random().toString(36).substr(2, 4); // Generating a random string of 4 characters
    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomPart = "";
    for (let i = 0; i < 2; i++) {
      // Looping twice to get 2 more characters
      randomPart += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return `${timestamp}-${randomString}-${randomPart}`;
  };

  const generateUid8 = () => {
    const timestamp = new Date().getTime().toString(36);
    const randomString1 = Math.random().toString(36).substr(2, 3); // Generating a random string of 3 characters
    const randomString2 = Math.random().toString(36).substr(2, 3); // Generating another random string of 3 characters
    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomPart = "";
    for (let i = 0; i < 2; i++) {
      // Looping twice to get 2 more characters
      randomPart += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return `${timestamp}-${randomString1}-${randomString2}-${randomPart}`;
  };

  const generateUid16 = () => {
    const timestamp = new Date().getTime().toString(36);
    const randomString1 = Math.random().toString(36).substr(2, 6); // Random string, first part
    const randomString2 = Math.random().toString(36).substr(2, 6); // Random string, second part
    const randomString3 = Math.random().toString(36).substr(2, 4); // Random string, third part
    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomPart = "";
    for (let i = 0; i < 4; i++) {
      randomPart += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return `${timestamp}-${randomString1}-${randomString2}-${randomString3}-${randomPart}`;
  };


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);



    

  const contextValue = {
    generateUid16,
    generateUid8,
    generateUid6,
    TimeAgo,
    isMobile,
    who,
    setwho,

    // ---------- Auth
    currentUser,
    userData,

    // ---------- Data
    lastSeen,
    loading,
    userNickname,
    userName,
    userEmail,
    aboutMe,
    dateOfBirth,
    language,
    status,
    userId,
    userCountry,
    joinDate,
    isAdmin,
    ipAddress,
  };

  return (
    <GeneralBrainContext.Provider value={contextValue}>
      {children}
    </GeneralBrainContext.Provider>
  );
};

export const useGeneralBrain = () => {
  const context = useContext(GeneralBrainContext);
  if (!context) {
    throw new Error(
      "useGeneralBrain must be used within a GeneralBrainProvider"
    );
  }
  return context;
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

export const Modal = Swal.mixin({
  toast: false,
  position: "center",
  showConfirmButton: true,
  confirmButtonText: "Close",
  didOpen: (modal) => {},
});

export const showConfirmDialog = async ({ title, text, icon } = {}) => {
  const { isConfirmed } = await Swal.fire({
    title: title || "Are you sure?",
    text: text || "This action cannot be undone!",
    icon: icon || "warning",
    showCancelButton: true,
    confirmButtonColor: "black",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, confirm!",
  });
  return isConfirmed;
};

