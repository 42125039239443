export const sexOptions = [
  { name: "male", value: "male", info: "M" },
  { name: "female", value: "female", info: "F" },
  { name: "Gay", value: "gay", info: "G" },
];

export const valorsOptions = [
  { name: "1 hora", value: "1 hora", info: "1" },
  { name: "30 minutos", value: "30 minutos", info: "F" },
  { name: "Pernoite", value: "Pernoite", info: "G" },
  { name: "Diária", value: "Diária", info: "G" },
  { name: "Pernoite", value: "Pernoite", info: "G" },
  { name: "Pernoite", value: "Pernoite", info: "G" },
  { name: "Pernoite", value: "Pernoite", info: "G" },
];

export const serviceOptions = [
  { name: "escort", value: "escort", info: "E" },
  { name: "content", value: "content", info: "C" },
];

export const timeOptions = [
  { name: "5 minutos", value: 5, info: "5" },
  { name: "8 minutos", value: 8, info: "8" },
  { name: "10 minutos", value: 10, info: "10" },
  { name: "12 minutos", value: 12, info: "12" },
  { name: "15 minutos", value: 15, info: "15" },
];
